import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import QRCode from 'react-qr-code';

export default function Brochure() {
  useEffect(() => {
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    return () => {
      unlisten();
    };
  }, []);
  return (
    <>
      <Helmet>
        <title>Green Rangers Consulting - Brochure</title>
        <meta name="description" content="Brochure" />
      </Helmet>
      <div className="h-1/2 md:pt-20">
        <div className="h-auto m-auto w-full max-w-screen-sm p-16">
          <QRCode
            size={512}
            style={{ height: 'auto', maxWidth: '100%', width: '100%' }}
            value={'https://greenrangersconsulting.com/pdf/brochure.pdf'}
            viewBox={`0 0 256 256`}
          />
        </div>
      </div>
    </>
  );
}
