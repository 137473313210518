import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Header from './Components/Common/Header';
import Footer from './Components/Common/Footer';
import About from './pages/About';
import Services from './pages/Services';
// import Enquiry from './pages/Enquiry';
import Contact from './pages/Contact';
import Blogs from './pages/Blogs';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Terms from './pages/Terms';
import Sustainability from './pages/Sustainability';
import { Helmet } from 'react-helmet';
import Brochure from './pages/Brochure';

function App() {
  return (
    <BrowserRouter>
      <Helmet>
        <title>Green Rangers Consulting</title>
        <meta name="description" content="Earth-friendly Quality Solutions" />
        <meta name="keywords" content="Consulting, Import, Export, Financial, Marketing" />
      </Helmet>
      <Header />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/about-us" element={<About />} />
        <Route exact path="/services" element={<Services />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/blogs" element={<Blogs />} />
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route exact path="/terms" element={<Terms />} />
        <Route exact path="/sustainability" element={<Sustainability />} />
        <Route exact path="/brochure" element={<Brochure />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
